import {
    makeStyles,
    shorthands,
    tokens,
    Subtitle1,
    Image,
    Button,
    Textarea,
    Text,
    TextareaOnChangeData,
} from '@fluentui/react-components';
import {
    SendRegular,
    SearchRegular,
    DismissCircleRegular,
    Compose28Regular,
} from '@fluentui/react-icons';
import 'Styles/ChatCards.Module.css';
import { useEffect, useRef, useState, useCallback } from 'react';

import { createFadeInOutComponentWithMotion2 } from 'Components/motionComponentCreators';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useLogging } from 'Hooks/useLogging';
import { EventTypes } from 'Models/EventTypes';
import { ErmMetricsEvent } from 'Models/Metrics';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

import 'Styles/ErmMicrophone.Module.css';

type Props = {
    isSendMessageButtonDisabled: boolean;
    onSendMessageButtonClick: (
        message: string,
        ermMetrics?: ErmMetricsEvent,
        ermLanguageSetting?: string,
    ) => Promise<void>;
    resetTranscript: () => void;
    transcript: string;
    isRecording: boolean;
    toggleRecording: (isRecording: boolean) => void;
    onChange: (message: string) => void;
    userMessage: PostErmMessageRequest;
    isNewMessage: boolean;
    setIsNewMessage: (isNewMessage: boolean) => void;
    tutorialMessage: string;
    logAndTrackErmEventSequence: (eventType: EventTypes) => void;
};

const useStyles = makeStyles({
    title: {
        ...shorthands.margin(0, 0, '10px'),
        textAlign: 'center',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};

export const ErmMessageCard: React.FC<Props> = (props: Props) => {
    const ErmLoading = useAppSelector(
        (store) => store.userInterface.ErmLoading,
    );

    const {
        onSendMessageButtonClick,
        toggleRecording,
        resetTranscript,
        transcript,
        isRecording,
        onChange,
        userMessage,
        isNewMessage,
        setIsNewMessage,
        tutorialMessage,
        logAndTrackErmEventSequence,
    } = props;
    const { logEvent } = useLogging();
    const [eventSequence, setEventSequence] = useState<EventTypes[]>([]);
    const [message, setMessage] = useState('');
    const [sendButton, setSendButton] = useState(false);
    const [editText, setEditText] = useState(false);
    const sendButtonRef = useRef<HTMLButtonElement>(null);
    const textAreaRef = useRef<HTMLDivElement>(null);
    const textareaInputRef = useRef<HTMLTextAreaElement>(null);
    const isChatDisabled = useAppSelector(
        (store) => store.userInterface.isChatDisabled,
    );

    const ermLanguage = useAppSelector(
        (store) => store.userInterface.ermLanguage,
    );

    // UseEffect for focusing on the text area when the user clicks on the card (edit text = true)
    useEffect(() => {
        if (editText && textareaInputRef.current) {
            const textarea = textareaInputRef.current;
            textarea.focus();
            textarea.setSelectionRange(
                textarea.value.length,
                textarea.value.length,
            );
        }
    }, [editText]);

    // UseEffect for handling clicks outside the text area
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            textareaInputRef.current &&
            !textareaInputRef.current.contains(event.target as Node) &&
            (!sendButtonRef.current ||
                !sendButtonRef.current.contains(event.target as Node))
        ) {
            setEditText(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isRecording && transcript.trim() !== '') {
            setSendButton(true);
            setMessage('');
        } else {
            setMessage(transcript);
            setSendButton(transcript.trim() !== '');
        }
    }, [isRecording, transcript]);

    // Function for sending message
    const clickSendButton = async () => {
        if (isRecording) {
            toggleRecording(false);
            setMessage(transcript);
        }
        setEditText(false);
        setSendButton(false);
        await onSendMessageButtonClick(
            message.trim() !== '' ? message : transcript,
        );
        resetTranscript();
        setMessage('');
        setEventSequence([]);
    };

    const clickClearButton = () => {
        setSendButton(false);
        setMessage('');
        resetTranscript();
        if (!isRecording) {
            setEditText(true);
            logAndTrackErmEventSequence(
                EventTypes.ERM_CLEAR_AND_TEXTAREA_FOCUSED,
            );
            return;
        }
        logAndTrackErmEventSequence(
            EventTypes.ERM_CLEAR_AND_MICROPHONE_INITIATED,
        );
    };

    // Initiate/end recording when the Aurora logo is clicked and log message if there is one
    const clickAuroraLogo = async () => {
        !isRecording
            ? logAndTrackErmEventSequence(
                  EventTypes.ERM_MICROPHONE_BUTTON_START_CLICKED,
              )
            : logAndTrackErmEventSequence(
                  EventTypes.ERM_MICROPHONE_BUTTON_STOP_CLICKED,
              );
        toggleRecording(!isRecording);
    };

    // Send message when Enter key is pressed - may remove this when moving to mobile
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            clickSendButton();
        }
    };
    const getPromptCard = () => {
        if (isRecording) {
            return transcript === '' ? '. . .' : transcript;
        } else if (tutorialMessage !== '') {
            return tutorialMessage;
        } else if (message !== '') {
            return message;
        } else if (userMessage.message !== '' && !isNewMessage) {
            return userMessage.message;
        }
        return ermLanguage == 'Spanish'
            ? 'Presiona aquí para escribir tu pregunta'
            : 'Tap here to type your question';
    };

    const getHintCard = () => {
        if (ErmLoading) {
            return ermLanguage == 'Spanish'
                ? 'Encontrando respuesta, por favor espere..'
                : 'Finding answer, please wait...';
        } else if (tutorialMessage !== '' || message !== '') {
            return ermLanguage == 'Spanish'
                ? 'Presiona el logo de Aurora para volver a grabar tu mensaje.'
                : 'Tap the Aurora logo to re-record your message';
        } else if (userMessage.message !== '' && !isNewMessage) {
            return '';
        } else if (isRecording) {
            return ermLanguage == 'Spanish'
                ? 'Presiona el logo de Aurora nuevamente para detener la grabación.'
                : 'Tap the Aurora logo again to stop recording';
        }
        return ermLanguage == 'Spanish'
            ? 'Presiona el logo de Aurora para hablar'
            : 'Tap the Aurora logo below to speak';
    };
    const getClickAnimation = () => {
        if (ErmLoading || !isNewMessage || message !== '' || isRecording) {
            return false;
        }
        return true;
    };

    const promptCard = getPromptCard();
    const hintCard = getHintCard();
    const clickAnimation = getClickAnimation();
    const FadeInOutIcon = createFadeInOutComponentWithMotion2({
        duration: 8000,
    });

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                paddingTop: '20px',
                paddingBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {hintCard && (
                    <div
                        style={{
                            backgroundColor: 'rgba(48, 69, 69, 0.2)',
                            paddingLeft: '13px',
                            paddingRight: '13px',
                            borderRadius: '30px',
                            paddingTop: '7px',
                            marginBottom: '7px',
                            marginTop: '0px',
                            marginRight: '20px',
                            marginLeft: '20px',
                            width: 'auto',
                        }}
                        ref={textAreaRef}
                    >
                        <Title>
                            <Text
                                size={500}
                                style={{
                                    userSelect: 'none',
                                }}
                            >
                                {hintCard}
                            </Text>
                        </Title>
                    </div>
                )}
                <Button
                    appearance="subtle"
                    style={{
                        background: 'transparent',
                        padding: '20px',
                        paddingTop: '50px',
                        overflow: 'visible',
                        WebkitTapHighlightColor: 'transparent',
                    }}
                    disabled={ErmLoading || !isNewMessage}
                    onClick={clickAuroraLogo}
                    data-testid="erm-aurora-logo"
                >
                    {clickAnimation && (
                        <FadeInOutIcon data-testid="fade-in-out-icon">
                            <Image
                                src={'/TapIcon.png'}
                                shape="circular"
                                height={70}
                                style={{
                                    userSelect: 'none',
                                    position: 'absolute',
                                    marginBottom: '50px',
                                    zIndex: 3,
                                    border: 'transparent',
                                    marginTop: '110px',
                                    marginLeft: '70px',
                                }}
                            />
                        </FadeInOutIcon>
                    )}
                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                        }}
                    >
                        <Image
                            src={'/EmbossedAurora.png'}
                            shape="circular"
                            height={145}
                            width={145}
                            style={{
                                userSelect: 'none',
                                position: 'relative',
                                marginBottom: '50px',
                                marginTop: '15px',
                                zIndex: 2,
                                border: 'transparent',
                                boxShadow: '0px 0px 0px 5px rgb(21, 27, 26)',
                            }}
                        />
                        {isRecording && (
                            <div
                                className="mic-shadow"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '0',
                                    width: '145px',
                                    height: '145px',
                                    zIndex: 1,
                                }}
                                data-testid="erm-mic-shadow"
                            ></div>
                        )}
                        {ErmLoading && (
                            <div
                                className="mic-loading"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '0',
                                    width: '145px',
                                    height: '145px',
                                    zIndex: 1,
                                }}
                            ></div>
                        )}
                    </div>
                </Button>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: tokens.colorNeutralBackground6,
                            paddingTop: '10px',
                            paddingBottom: '4px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            borderRadius: '30px',
                            marginRight: '20px',
                            marginLeft: '20px',
                            width: editText ? '50vw' : 'auto',
                        }}
                        ref={textAreaRef}
                        onClick={() =>
                            !ErmLoading && !isRecording && isNewMessage
                                ? (logAndTrackErmEventSequence(
                                      EventTypes.ERM_TEXTAREA_CLICKED,
                                  ),
                                  setEditText(true))
                                : null
                        }
                        data-testid="erm-edit-button"
                    >
                        {!editText ? (
                            <Title>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {promptCard !== '. . .' &&
                                        isNewMessage &&
                                        !ErmLoading && (
                                            <SearchRegular
                                                style={{
                                                    marginRight: '10px',
                                                    height: '35px',
                                                    alignSelf: 'center',
                                                    flexShrink: 0,
                                                }}
                                                data-testid="erm-edit-icon"
                                            />
                                        )}
                                    <Text
                                        size={600}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            userSelect: 'none',
                                            height: 'auto',
                                            paddingRight: '10px',
                                            paddingLeft: '5px',
                                            textAlign: 'center',
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {promptCard}
                                    </Text>
                                </div>
                            </Title>
                        ) : (
                            <Textarea
                                size="large"
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    marginBottom: '10px',
                                    width: '100%',
                                }}
                                ref={textareaInputRef}
                                defaultValue={message}
                                onKeyDown={handleKeyDown}
                                disabled={isRecording || ErmLoading}
                                onChange={(
                                    _event: React.ChangeEvent<HTMLTextAreaElement>,
                                    data: TextareaOnChangeData,
                                ) => {
                                    onChange(data.value);
                                    setMessage(data.value);
                                    resetTranscript();
                                    setSendButton(data.value !== '');
                                }}
                                data-testid="erm-textarea"
                            ></Textarea>
                        )}
                    </div>
                </div>

                {sendButton && isNewMessage && !ErmLoading && (
                    <div style={{ marginTop: '25px' }}>
                        <Button
                            style={{
                                marginRight: '50px',
                                borderRadius: '30px',
                                userSelect: 'none',
                                fontSize: '18px',
                                paddingTop: '13px',
                                paddingBottom: '13px',
                                paddingLeft: '20px',
                                paddingRight: '27px',
                                WebkitTapHighlightColor: 'transparent',
                                backgroundColor:
                                    tokens.colorNeutralBackgroundStatic,
                                borderColor: 'transparent',
                            }}
                            onClick={clickClearButton}
                            data-testid="erm-dismiss-button"
                        >
                            <DismissCircleRegular
                                style={{ marginRight: '10px' }}
                            />
                            {ermLanguage === 'Spanish' ? 'Borrar' : 'Clear'}
                        </Button>
                        <Button
                            style={{
                                borderRadius: '30px',
                                userSelect: 'none',
                                fontSize: '18px',
                                paddingTop: '13px',
                                paddingBottom: '13px',
                                paddingLeft: '20px',
                                paddingRight: '27px',
                                WebkitTapHighlightColor: 'transparent',
                                backgroundColor:
                                    tokens.colorNeutralBackgroundStatic,
                                borderColor: 'transparent',
                            }}
                            onClick={() => {
                                clickSendButton();
                            }}
                            data-testid="erm-send-button"
                        >
                            <SendRegular style={{ marginRight: '10px' }} />
                            {ermLanguage === 'Spanish' ? 'Enviar' : 'Send'}
                        </Button>
                    </div>
                )}
                {!isNewMessage && (
                    <Button
                        onClick={() => {
                            setIsNewMessage(true);
                            logEvent(EventTypes.ERM_NEW_CHAT_BUTTON_CLICKED);
                        }}
                        icon={<Compose28Regular />}
                        style={{
                            borderRadius: '20px',
                            userSelect: 'none',
                            WebkitTapHighlightColor: 'transparent',
                            backgroundColor: 'transparent',
                            marginTop: '20px',
                            fontSize: '18px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            paddingLeft: '20px',
                            paddingRight: '25px',
                        }}
                        data-testid="erm-new-message-button"
                    >
                        {ermLanguage === 'Spanish'
                            ? 'Nuevo mensaje'
                            : 'New Message'}
                    </Button>
                )}
            </div>
        </div>
    );
};
