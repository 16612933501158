import {
    Button,
    Image,
    makeStyles,
    shorthands,
    Tooltip,
} from '@fluentui/react-components';
import {
    ArrowExportLtr24Regular,
    ArrowExportRtlRegular,
    Compose28Regular,
    ChatMultipleHeartFilled,
    AlertFilled,
} from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';

import { Feature } from 'Config/features';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useFeature } from 'Hooks/useFeature';
import { useLogging } from 'Hooks/useLogging';
import { EventTypes } from 'Models/EventTypes';
import {
    useGetActiveBannerQuery,
    useGetThreadDetailsQuery,
    usePostShowBannerMutation,
    usePostThreadMutation,
} from 'Services/API/Aurora';
import 'Styles/Header.Module.css';
import {
    setIsNewThread,
    setSelectedThreadId,
    toggleLeftOpen,
    toggleRightOpen,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

const useStyles = makeStyles({
    leftButtons: {
        ...shorthands.margin('20px'),
    },
});

export const HeaderContainer: React.FC = () => {
    const styles = useStyles();
    const { logEvent } = useLogging();
    const leftPanelOpen = useAppSelector(
        (store) => store.userInterface.leftPanelOpen,
    );
    const rightPanelOpen = useAppSelector(
        (store) => store.userInterface.rightPanelOpen,
    );

    const { data: banner } = useGetActiveBannerQuery();
    const [postShowBanner] = usePostShowBannerMutation();
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);

    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [postThread, { data: postThreadResult }] = usePostThreadMutation();
    useEffect(() => {
        if (postThreadResult) {
            updateStore(setSelectedThreadId(postThreadResult.id));
        }
    }, [postThreadResult]);

    const isChatDisabled = useAppSelector(
        (store) => store.userInterface.isChatDisabled,
    );

    const isNewChatButtonDisabled =
        isChatDisabled ||
        (Boolean(selectedThread) && selectedThread?.messageCount === 0);

    const handleToggleLeftSidebar = useCallback(() => {
        logEvent(
            leftPanelOpen
                ? EventTypes.LEFT_SIDEBAR_CLOSE_CLICKED
                : EventTypes.LEFT_SIDEBAR_OPEN_CLICKED,
        );
        updateStore(toggleLeftOpen());
    }, [leftPanelOpen, logEvent]);

    const handleToggleRightSidebar = useCallback(() => {
        logEvent(EventTypes.RIGHT_SIDEBAR_OPEN_CLICKED);
        updateStore(toggleRightOpen());
    }, [logEvent]);

    const handleToggleNewChat = useCallback(() => {
        updateStore(setIsNewThread(true));
        postThread();
    }, [postThread]);

    const [isFeedbackHovered, setIsFeedbackHovered] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleFeedbackHoverTrue = useCallback(
        () => setIsFeedbackHovered(true),
        [],
    );
    const handleFeedbackHoverFalse = useCallback(
        () => setIsFeedbackHovered(false),
        [],
    );
    const handleNotificationsHoverTrue = useCallback(
        () => setIsHovered(true),
        [],
    );
    const handleNotificationsHoverFalse = useCallback(
        () => setIsHovered(false),
        [],
    );
    const handleNotificationsClick = useCallback(() => {
        if (banner?.id) {
            postShowBanner(banner.id);
        }
    }, [banner, postShowBanner]);

    const { enabled: isPharmacyUser } = useFeature(Feature.PharmacySurvey);

    return (
        <div className="HeaderContainer">
            <div className="InnerHeaderLeft">
                <Button
                    appearance="transparent"
                    icon={
                        leftPanelOpen ? (
                            <ArrowExportRtlRegular />
                        ) : (
                            <ArrowExportLtr24Regular />
                        )
                    }
                    className={styles.leftButtons}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                    }}
                    onClick={handleToggleLeftSidebar}
                    data-testid="toggle-left-sidebar-button"
                ></Button>

                {!leftPanelOpen && (
                    <Button
                        appearance="transparent"
                        icon={<Compose28Regular />}
                        style={{
                            marginRight: '20px',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                        }}
                        onClick={handleToggleNewChat}
                        disabled={isNewChatButtonDisabled}
                        data-testid="new-chat-small-button"
                    ></Button>
                )}
                <Image
                    alt="Aurora Logo"
                    shape="circular"
                    src="/logo192.png"
                    height={40}
                    width={40}
                />
                <h2 style={{ paddingLeft: '15px' }}>Aurora</h2>
            </div>
            <div className="InnerHeaderRight">
                {/* <Button
                    appearance="subtle"
                    icon={<ShareIos24Regular />}
                ></Button>
                <Button
                    appearance="subtle"
                    className={styles.leftButtons}
                    icon={<MoreVertical24Regular />}
                ></Button> */}
                <a
                    href={
                        isPharmacyUser
                            ? 'https://forms.office.com/Pages/ResponsePage.aspx?id=aKw8YtC18UWRCTEiw5dMyRNX3zfpR7RIu6el7FEHVdNUNVlETVpJOVQxVk9CWVdYMFozR0YyNDlZOC4u'
                            : 'https://forms.office.com/Pages/ResponsePage.aspx?id=aKw8YtC18UWRCTEiw5dMyRNX3zfpR7RIu6el7FEHVdNURUgwUE4wVTU2SUw5RzVOMVRXQVhHVTZBVC4u'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <Tooltip
                        content="Share Feedback"
                        relationship="description"
                        withArrow
                    >
                        <Button
                            // appearance="subtle"
                            aria-label="Bold"
                            icon={
                                <ChatMultipleHeartFilled
                                    style={{
                                        // borderColor: 'var(--colorNeutralBackground)',
                                        color: isFeedbackHovered
                                            ? 'var(--colorBrandForeground1)'
                                            : 'var(--colorNeutralBackground)',
                                    }}
                                />
                            }
                            name="docMenu"
                            value="doclMenu"
                            style={{
                                borderColor: isFeedbackHovered
                                    ? 'var(--colorBrandForeground1)'
                                    : 'var(--colorNeutralBackground)',
                                borderRadius: '50%',
                                backgroundColor: 'transparent',
                            }}
                            onMouseEnter={handleFeedbackHoverTrue}
                            onMouseLeave={handleFeedbackHoverFalse}
                            data-testid="feedback-button"
                        />
                    </Tooltip>
                </a>
                {banner?.isHidden && Boolean(banner?.message) && (
                    <Tooltip
                        content="View Notifications"
                        relationship="description"
                        withArrow
                    >
                        <Button
                            // appearance="subtle"
                            aria-label="Bold"
                            icon={
                                <AlertFilled
                                    style={{
                                        // borderColor: 'var(--colorNeutralBackground)',
                                        color: isHovered
                                            ? 'var(--colorBrandForeground1)'
                                            : 'var(--colorNeutralBackground)',
                                    }}
                                />
                            }
                            name="docMenu"
                            value="doclMenu"
                            style={{
                                borderColor: isHovered
                                    ? 'var(--colorBrandForeground1)'
                                    : 'var(--colorNeutralBackground)',
                                borderRadius: '50%',
                                backgroundColor: 'transparent',
                                margin: '0',
                            }}
                            className={styles.leftButtons}
                            onMouseEnter={handleNotificationsHoverTrue}
                            onMouseLeave={handleNotificationsHoverFalse}
                            onClick={handleNotificationsClick}
                            data-testid="show-banner-button"
                        />
                    </Tooltip>
                )}
                {!rightPanelOpen && (
                    <Button
                        appearance="transparent"
                        icon={<ArrowExportRtlRegular />}
                        style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                        }}
                        onClick={handleToggleRightSidebar}
                        data-testid="toggle-right-sidebar-button"
                    ></Button>
                )}
            </div>
        </div>
    );
};
