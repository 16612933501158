import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { PostTimeSavedSurvey } from '../dto';

export const postTimeSavedSurvey = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, PostTimeSavedSurvey>({
        query: (survey) => ({
            url: `/v1/Metrics/SubmitTimeSavedSurvey`,
            method: 'POST',
            body: {
                messageId: survey.messageId,
                minutesSaved: survey.minutesSaved,
            },
        }),
        async onQueryStarted(survey, { dispatch, queryFulfilled }) {
            const result = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadMessages',
                    {
                        threadId: survey.threadId,
                        pageNumber: 1,
                    },
                    (draft) => {
                        const message = draft.items.find(
                            (m) => m.id === survey.messageId,
                        );
                        if (message) {
                            message.timeSavedSurvey = {
                                minutesSaved: survey.minutesSaved,
                            };
                        }
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch {
                result.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.TimeSavedSurveyPost,
                        body: '',
                        position: 'bottom',
                    }),
                );
            }
        },
    });
