import { createMotionComponent } from '@fluentui/react-components';

type Props = {
    duration: number;
};

export const createFadeInOutComponent = ({ duration }: Props) => {
    return createMotionComponent({
        keyframes: [
            { opacity: 0 },
            { opacity: 1 },
            { opacity: 1 },
            { opacity: 0 },
        ],

        duration,
        iterations: 1,

        reducedMotion: {
            iterations: 1,
        },
    });
};

export const createFadeInOutComponentWithMotion = ({ duration }: Props) => {
    return createMotionComponent({
        keyframes: [
            { transform: 'translateY(0%)', opacity: 0 },
            { transform: 'translateY(-15%)', opacity: 1 },
            { transform: 'translateY(-30%%)', opacity: 1 },
            { transform: 'translateY(-45%)', opacity: 0 },
        ],
        duration,
        iterations: 1,

        reducedMotion: {
            iterations: 1,
        },
    });
};
export const createFadeInOutComponentWithMotion2 = ({ duration }: Props) => {
    return createMotionComponent({
        keyframes: [
            { transform: 'scale(1)', opacity: 0, offset: 0 },
            { transform: 'scale(1)', opacity: 0.4, offset: 0.1 },
            { transform: 'scale(1)', opacity: 0.4, offset: 0.3 },

            { transform: 'scale(0.8)', opacity: 0.4, offset: 0.35 },
            { transform: 'scale(1)', opacity: 0.4, offset: 0.4 },
            { transform: 'scale(1)', opacity: 0.4, offset: 0.65 },
            { transform: 'scale(1)', opacity: 0, offset: 0.7 },
            { transform: 'scale(1)', opacity: 0, offset: 1 }, // Pause keyframe
        ],
        duration: duration,
        iterations: Infinity,

        reducedMotion: {
            iterations: 1,
        },
    });
};
