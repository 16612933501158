import { useState, useEffect } from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';

import { useAppSelector } from 'Hooks/useAppSelector';
import { useLazyGetSpeechTokenQuery } from 'Services/API/Aurora';

export const useErmSpeechRecognitionHook = () => {
    const [record, setRecord] = useState<boolean>(false);
    const [
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
    ] = useState(false);
    const { transcript, resetTranscript } = useSpeechRecognition();
    const MAX_RECORDING_TIME_MILLISECONDS = 5000;
    const [getSpeechTokenQuery] = useLazyGetSpeechTokenQuery();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [tokenRefreshTimeoutId, setTokenRefreshTimeoutId] =
        useState<NodeJS.Timeout | null>(null);
    const ermLanguage = useAppSelector(
        (store) => store.userInterface.ermLanguage,
    );

    const fetchSpeechToken = async () => {
        const result = await getSpeechTokenQuery();
        if ('data' in result) {
            if (result.data) {
                const expirationTime = result.data.expirationTime;
                const refreshTime = expirationTime - Date.now() - 60000; // Refresh 1 minute before expiration
                if (tokenRefreshTimeoutId) {
                    clearTimeout(tokenRefreshTimeoutId);
                }
                const id = setTimeout(fetchSpeechToken, refreshTime);
                setTokenRefreshTimeoutId(id);
            }
        }
    };

    useEffect(() => {
        fetchSpeechToken();
        return () => {
            if (tokenRefreshTimeoutId) {
                clearTimeout(tokenRefreshTimeoutId);
            }
        };
    }, []);

    const checkMicrophonePermissionsAndStartRecording = async () => {
        if (navigator.permissions) {
            try {
                const permissionStatus = await navigator.permissions.query({
                    name: 'microphone' as PermissionName,
                });
                if (permissionStatus.state === 'denied') {
                    setIsMicrophonePermissionPopupVisible(true);
                    permissionStatus.onchange = async () => {
                        if (permissionStatus.state !== 'denied') {
                            await startListening();
                            setRecord(true);
                        } else {
                            checkMicrophonePermissionsAndStartRecording();
                        }
                    };
                } else {
                    await startListening();
                    setRecord(true);
                }
            } catch (error) {
                console.error('Error querying microphone permissions:', error);
                fallbackToGetUserMedia();
            }
        } else {
            fallbackToGetUserMedia();
        }
    };

    const fallbackToGetUserMedia = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await startListening();
            setRecord(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
            setIsMicrophonePermissionPopupVisible(true);
        }
    };

    const abortListening = async () => {
        await SpeechRecognition.abortListening();
    };
    const startListening = async () => {
        await SpeechRecognition.startListening({
            continuous: true,
            language: ermLanguage === 'Spanish' ? 'es-US' : 'en-US',
        });
    };

    const toggleRecording = async (recording: boolean) => {
        if (!recording) {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
            }

            await abortListening();
            setRecord(recording);
        } else {
            resetTranscript();
            await checkMicrophonePermissionsAndStartRecording();
        }
    };

    useEffect(() => {
        if (record) {
            const id = setTimeout(() => {
                toggleRecording(false);
            }, MAX_RECORDING_TIME_MILLISECONDS);
            setTimeoutId(id);

            return () => clearTimeout(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript, record]);

    return {
        record,
        transcript,
        resetTranscript,
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
        toggleRecording,
    };
};
