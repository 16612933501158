import {
    Button,
    makeStyles,
    tokens,
    Text,
    Input,
    InputOnChangeData,
} from '@fluentui/react-components';
import { EmojiHandFilled } from '@fluentui/react-icons';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';

import { createFadeInOutComponentWithMotion } from 'Components/motionComponentCreators';
import { useAppSelector } from 'Hooks/useAppSelector';
import { usePostTimeSavedSurveyMutation } from 'Services/API/Aurora';

// Define the time options
interface TimeOption {
    id: string;
    label: string;
    value: number | string; // in minutes
}

// Styles for the component
const useStyles = makeStyles({
    container: {
        display: 'flex',
        flewFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },
    surveyContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        rowGap: '4px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'end',
    },
    surveyQuestion: {
        color: tokens.colorNeutralForeground1,
        textAlign: 'end',
        '@media (max-width: 500px)': {
            textAlign: 'end',
        },
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
        flexWrap: 'wrap',
        paddingLeft: '16px',
        '@media (max-width: 500px)': {
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
        },
    },
    option: {
        minWidth: '30px',

        backgroundColor: tokens.colorBrandBackground2,
        ':hover': {
            backgroundColor: tokens.colorBrandBackground2Hover,
        },
    },
    selectedOption: {
        minWidth: '30px',
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralForegroundOnBrand,
    },
});

const MAX_TIME_SAVED = 10;
const SUBMIT_DELAY_MS = 2000;
const FadeInOutIcon = createFadeInOutComponentWithMotion({ duration: 3000 });

const fixedTimeOptions = [
    { id: 'option2', label: '5m', value: 5 },
    { id: 'option3', label: '15m', value: 15 },
    { id: 'option4', label: '30m', value: 30 },
    { id: 'option5', label: '1h', value: 60 },
];

type Props = {
    messageId?: number;
    disabled?: boolean;
};

export const TimeSavedSurvey: React.FC<Props> = ({ messageId, disabled }) => {
    const styles = useStyles();
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [customInputValue, setCustomInputValue] = useState<string>('');

    const [timeOptions, setTimeOptions] = useState<TimeOption[]>([
        ...fixedTimeOptions,
        { id: 'option6', label: 'Custom', value: 'custom' },
    ]);

    const [showSubmittedMessage, setShowSubmittedMessage] = useState(false);

    const selectRef = useRef<HTMLSelectElement>(null);
    const [showCustomInput, setShowCustomInput] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const [postTimeSavedSurvey, { isLoading }] =
        usePostTimeSavedSurveyMutation();

    const handleOptionClick = useCallback(
        (optionId: string) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setSelectedOption(optionId);

            const option = timeOptions.find((opt) => opt.id === optionId);
            if (option && option.id !== 'option6') {
                timerRef.current = setTimeout(() => {
                    setShowSubmittedMessage(true);
                    if (threadId && messageId) {
                        postTimeSavedSurvey({
                            threadId: threadId,
                            messageId: messageId,
                            minutesSaved: Number(option.value),
                        });
                    }
                }, SUBMIT_DELAY_MS);
                return;
            }
            if (option && option.id === 'option6') {
                setTimeOptions(fixedTimeOptions);
                setShowCustomInput(true);
                selectRef.current?.click();
            }
        },
        [
            messageId,
            postTimeSavedSurvey,
            threadId,
            timeOptions,
            setShowSubmittedMessage,
        ],
    );

    const onCustomInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
                setShowSubmittedMessage(true);
                if (threadId && messageId) {
                    postTimeSavedSurvey({
                        threadId: threadId,
                        messageId: messageId,
                        minutesSaved: Number(data.value) * 60,
                    });
                }
            }, SUBMIT_DELAY_MS);
            const dataNumber = Number(data.value);
            if (
                (!isNaN(dataNumber) && dataNumber <= MAX_TIME_SAVED) ||
                data.value === ''
            ) {
                setCustomInputValue(String(dataNumber));
            }
        },
        [messageId, postTimeSavedSurvey, threadId, setShowSubmittedMessage],
    );

    if (!threadId || !messageId) {
        return null;
    }

    const SubmittedMessage = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    textAlign: 'end',
                    gap: '4px',
                }}
            >
                <div>Thank you for your feedback!</div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {showSubmittedMessage ? (
                <FadeInOutIcon>
                    <div
                        style={{
                            display: 'flex',
                            color: tokens.colorBrandForeground2,
                        }}
                    >
                        <EmojiHandFilled
                            style={{
                                fontSize: '24px',
                                color: tokens.colorBrandForeground2,
                            }}
                        />
                        <SubmittedMessage />
                    </div>
                </FadeInOutIcon>
            ) : (
                !disabled && (
                    <div className={styles.surveyContainer}>
                        <Text className={styles.surveyQuestion}>
                            Did this response save time?
                        </Text>
                        <div style={{ height: '8px' }} />
                        <div className={styles.optionsContainer}>
                            {timeOptions.map((option) => (
                                <Button
                                    key={option.id}
                                    className={`${selectedOption === option.id ? styles.selectedOption : styles.option}`}
                                    onClick={() => handleOptionClick(option.id)}
                                    appearance={
                                        selectedOption === option.id
                                            ? 'primary'
                                            : 'subtle'
                                    }
                                    size="small"
                                >
                                    {option.label}
                                </Button>
                            ))}
                            {showCustomInput && (
                                <Input
                                    style={{ maxWidth: '45px' }}
                                    size="small"
                                    appearance="underline"
                                    contentAfter={'hrs'}
                                    placeholder="0"
                                    value={customInputValue}
                                    onChange={onCustomInputChange}
                                    autoFocus
                                />
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
