import { shorthands, makeStyles } from '@fluentui/react-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TermsAndConditions } from 'Components/TermsAndConditions';
import { Chat } from 'Containers/Chat';
import { LeftSidebar } from 'Containers/LeftSidebar';
import { RightSidebar } from 'Containers/RightSidebar';
import { useTermsAndConditions } from 'Hooks/useAlerts';
import { useAppSelector } from 'Hooks/useAppSelector';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        // backgroundColor: tokens?.colorNeutralBackground2,
        ...shorthands.overflow('hidden'),
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding('16px'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    darkTintOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: '1',
    },
});

export const Home: React.FC = () => {
    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const { getTerms } = useTermsAndConditions();

    useEffect(() => {
        getTerms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentUrl = location.pathname + location.search + location.hash;
        const codeIndex = currentUrl.indexOf('#code=');

        if (codeIndex !== -1) {
            const newUrl = currentUrl.substring(0, codeIndex);
            navigate(newUrl, { replace: true });
        }
    }, [location.hash, location.pathname, location.search, navigate]);

    const termsPopover = useAppSelector(
        (store) => store.userInterface.termsPopover,
    );

    return (
        <>
            {termsPopover ? <TermsAndConditions></TermsAndConditions> : null}
            <div
                style={{
                    filter: termsPopover ? 'blur(10px)' : '',
                    position: 'relative',
                }}
            >
                <div
                    className={termsPopover ? styles.darkTintOverlay : ''}
                ></div>

                {/* Your main application content here */}

                <div className={styles.root}>
                    <LeftSidebar />
                    <Chat />
                    <RightSidebar />
                </div>
            </div>
        </>
    );
};
