export enum Role {
    Admin,
    Retail,
    Support,
    ERM,
    ERMOnly,
}

export enum Feature {
    AdminInterface,
    AttachFiles,
    BetaFlag,
    SpeechInput,
    PharmacySurvey,
    ErmAccess,
    ErmOnlyAccess,
}

export const FEATURE_CONFIG: Record<Feature, Role[]> = {
    [Feature.AdminInterface]: [Role.Admin],
    [Feature.AttachFiles]: [Role.Admin, Role.Support],
    [Feature.BetaFlag]: [Role.Admin, Role.Support],
    [Feature.SpeechInput]: [Role.Admin, Role.Support],
    [Feature.PharmacySurvey]: [Role.Retail],
    [Feature.ErmAccess]: [Role.Admin, Role.ERM, Role.ERMOnly],
    [Feature.ErmOnlyAccess]: [Role.ERMOnly],
};
