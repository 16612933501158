export enum EventTypes {
    NEW_CHAT_BUTTON_CLICKED = 'NewChatButtonClicked',
    USER_PROFILE_ICON_CLICKED = 'UserProfileIconClicked',
    LEFT_SIDEBAR_OPEN_CLICKED = 'LeftSidebarOpenClicked',
    LEFT_SIDEBAR_CLOSE_CLICKED = 'LeftSidebarCloseClicked',
    RIGHT_SIDEBAR_OPEN_CLICKED = 'RightSidebarOpenClicked',
    RIGHT_SIDEBAR_CLOSE_CLICKED = 'RightSidebarCloseClicked',
    SETTINGS_CLICKED = 'SettingsClicked',
    CONVERSATION_CONTEXT_CLICKED = 'ConversationContextClicked',
    REFERENCES_OPEN_CLICKED = 'ReferencesOpenClicked',
    REFERENCE_LINK_CLICKED = 'ReferenceLinkClicked',
    TOOLBAR_SKILLS_ICON_CLICKED = 'ToolbarSkillsIconClicked',
    TOOLBAR_ATTACH_ICON_CLICKED = 'ToolbarAttachIconClicked',
    TOOLBAR_MIC_ICON_CLICKED = 'ToolbarMicIconClicked',

    //CLick events - can be used to track number of clicks
    ERM_SEND_BUTTON_CLICKED = 'ErmSendButtonClicked',
    ERM_LINK_CLICKED = 'ErmLinkClicked',
    ERM_LOAD_MORE_CLICKED = 'ErmLoadMoreClicked',
    ERM_NEW_CHAT_BUTTON_CLICKED = 'ErmNewChatButtonClicked',
    ERM_TEXTAREA_CLICKED = 'ErmTextareaClicked',
    ERM_MICROPHONE_BUTTON_START_CLICKED = 'ErmMicrophoneButtonStartClicked',
    ERM_MICROPHONE_BUTTON_STOP_CLICKED = 'ErmMicrophoneButtonStopClicked',
    ERM_ENGLISH_BUTTON_CLICKED = 'ErmEnglishButtonClicked',
    ERM_SPANISH_BUTTON_CLICKED = 'ErmSpanishButtonClicked',

    // These are used when the component is inititated but not clicked by the user (Happens when user presses the 'Clear' button)
    ERM_CLEAR_AND_MICROPHONE_INITIATED = 'ErmClearAndMicrophoneInitiated',
    ERM_CLEAR_AND_TEXTAREA_FOCUSED = 'ErmClearAndTextareaFocused',
}
