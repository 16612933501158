import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { Role } from 'Config/features';
import { acquireToken } from 'Helpers/MsalHelper';

export const useRoles = (): Role[] => {
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const [roles, setRoles] = useState<Role[]>([]);

    useEffect(() => {
        async function fetchAdminTokenAndSetRole() {
            let token;
            try {
                token = await acquireToken(instance, account, [
                    process.env.REACT_APP_API_REQUEST_SCOPE!,
                ]);
            } catch (error) {
                console.error('Error acquiring token', error);
                return;
            }

            if (!token) {
                return;
            }
            const newRoles: Role[] = [];
            const roles = jwtDecode<{ roles: string[] }>(token).roles.map((r) =>
                r.toLowerCase(),
            );
            if (roles.includes('aurora.admins')) {
                setRoles([Role.Admin]);
                return;
            }
            if (roles.includes('erm.retail.users') && roles.length === 1) {
                setRoles([Role.ERMOnly]);
                return;
            }
            if (roles.includes('erm.retail.users')) {
                newRoles.push(Role.ERM);
            }
            if (roles.every((role) => role.endsWith('retail.users'))) {
                newRoles.push(Role.Retail);
            }
            if (roles.find((role) => role.endsWith('support.users'))) {
                newRoles.push(Role.Support);
            }
            setRoles(newRoles);
        }
        fetchAdminTokenAndSetRole();
    }, [instance, account]);

    return roles;
};
