import {
    Textarea,
    Popover,
    PopoverSurface,
    Card,
    TextareaOnChangeData,
    ProgressBar,
    Skeleton,
    SkeletonItem,
    makeStyles,
} from '@fluentui/react-components';
import { useState, useEffect, useRef, ChangeEvent, useCallback } from 'react';

import { DocumentPopover } from 'Components/DocumentPopover';
import { FileCard } from 'Components/FileCard';
import { ImageThumbnail } from 'Components/ImageThumbnail';
import { Feature } from 'Config/features';
import { ERROR_MESSAGE } from 'Constants';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useFeature } from 'Hooks/useFeature';
import {
    useGetThreadDetailsQuery,
    usePostAttachLocalFileToThreadMutation,
} from 'Services/API/Aurora';
import {
    closeDocumentSearch,
    setToastMessage,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import {
    formatDateTimeHumanReadable,
    isImageFile,
    readFileAsBase64,
    readFileAsBase64WithHeader,
    validateFile,
} from 'Utils/index';

import 'Styles/ChatCards.Module.css';
import 'Styles/TextArea.Module.css';

type Props = {
    message: string;

    threadId?: number;
    onChange: (message: string) => void;
    onEnterKeyPressed: (isShiftKeyPressed: boolean) => void;
    toggleRecording: (isRecording: boolean) => void;
    transcript: string;
    isRecording: boolean;
    resetTranscript: () => void;
    setLoadingSelectedDocument: (loading: boolean) => void;
    loadingSelectedDocument: boolean;
    isFetchingThreadDetails: boolean;
};

const useStyles = makeStyles({
    icon128: { fontSize: '128px' },
});

export const MessageBar: React.FC<Props> = (props: Props) => {
    const styles = useStyles();
    const { enabled: canAttachFiles } = useFeature(Feature.AttachFiles);
    const {
        message,
        threadId,
        onChange,
        onEnterKeyPressed,
        toggleRecording,
        isRecording,
        resetTranscript,
        setLoadingSelectedDocument,
        loadingSelectedDocument,
        isFetchingThreadDetails,
    } = props;
    const documentSearchOpen = useAppSelector(
        (store) => store.userInterface.documentSearchOpen,
    );
    const isChatDisabled = useAppSelector(
        (store) => store.userInterface.isChatDisabled,
    );

    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [popoverWidth, setPopoverWidth] = useState(0);

    const popoverRef = useRef<HTMLDivElement>(null);
    const [speechValue, setSpeechValue] = useState('');
    const [messageValue, setMessageValue] = useState('');

    const { data: threadDetails } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [
        attachLocalFile,
        { isLoading: isUploadingFile, isError: isErrorAttachingLocalFile },
    ] = usePostAttachLocalFileToThreadMutation();
    const files = threadDetails?.attachments;

    const notifyFileValidationError = (message: string) =>
        updateStore(
            setToastMessage({
                title: message,
                position: 'bottom',
            }),
        );

    const notifyFileLimitReached = () =>
        updateStore(
            setToastMessage({
                title: ERROR_MESSAGE.FileLimitReached,
                position: 'bottom',
            }),
        );

    // Update the speech value when the transcript changes
    useEffect(() => {
        if (!isRecording) {
            setSpeechValue((prevMessage) => {
                return prevMessage === ''
                    ? props.transcript
                    : `${prevMessage} ${props.transcript}`;
            });
        }
    }, [isRecording, props.transcript]);

    // Update the message value when the user finished recording
    useEffect(() => {
        if (!isRecording) {
            onChange(messageValue + speechValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speechValue, isRecording]);

    // Reset the message and speech value after a message has been sent
    useEffect(() => {
        if (isChatDisabled) {
            setSpeechValue('');
            setMessageValue('');
        } else {
            textareaRef.current?.focus();
        }
    }, [isChatDisabled]);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handlePaste = useCallback(
        async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
            if ((threadDetails?.attachments?.length ?? 0) >= 2) {
                notifyFileLimitReached();
                return;
            }

            const { items } = event.clipboardData;

            // Only handling the first two elements of the clipboard list because
            // various apps have weird behaviors when adding things to the clipboard
            // May need to revisit if we see bugs
            for (let i = 0; i < 2; i++) {
                if (!items[i]) {
                    continue;
                }
                // Try to get a file handle to check if item is a blob vs a file
                let fileHandle = await (
                    items[i] as any
                ).getAsFileSystemHandle();

                // file handle found
                if (
                    fileHandle &&
                    fileHandle.kind === 'file' &&
                    fileHandle.path
                ) {
                    const file = await fileHandle.getFile();
                    if (file) {
                        try {
                            validateFile(file.name, files);
                        } catch (e) {
                            if (e instanceof Error) {
                                notifyFileValidationError(e.message);
                                return;
                            }
                        }
                        attachLocalFile({
                            content: await readFileAsBase64(file),
                            contentUrl: await readFileAsBase64WithHeader(file),
                            contentType: file.type,
                            fileName: file.name,
                            threadId: threadId ?? 0,
                        });
                        return;
                    }
                    // No file handle, so check if it's a screenshot
                } else if (items[i].kind === 'file') {
                    const file = items[i].getAsFile();
                    if (file) {
                        try {
                            validateFile(file.name, files);
                        } catch (e) {
                            if (e instanceof Error) {
                                notifyFileValidationError(e.message);
                                return;
                            }
                        }
                        attachLocalFile({
                            content: await readFileAsBase64(file),
                            contentType: file.type,
                            fileName: `Screenshot ${formatDateTimeHumanReadable(new Date())}.png`,
                            threadId: threadId ?? 0,
                            contentUrl: await readFileAsBase64WithHeader(file),
                        });
                        return;
                    }
                    // if it is an html snippet, check to see if it has an img tag
                } else if (items[i].type === 'text/html') {
                    items[i].getAsString((imageInHtml) => {
                        const parser = new DOMParser();
                        const htmlDoc = parser.parseFromString(
                            imageInHtml,
                            'text/html',
                        );
                        const img = htmlDoc.getElementsByTagName('img')[0];
                        if (img?.src) {
                            try {
                                validateFile('image.jpeg', files);
                            } catch (e) {
                                if (e instanceof Error) {
                                    notifyFileValidationError(e.message);
                                    return;
                                }
                            }
                            const url = window.URL || window.webkitURL;
                            attachLocalFile({
                                content: img.src.split(',')[1],
                                contentType: 'image/jpeg',
                                fileName: `Image ${formatDateTimeHumanReadable(new Date())}.jpeg`,
                                threadId: threadId ?? 0,
                                contentUrl: img.src,
                            });
                        }
                    });
                }
                return;
            }
        },
        [attachLocalFile, files, threadDetails?.attachments?.length, threadId],
    );

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;

        if (textarea) {
            textarea.style.height = 'auto'; // Reset height to auto
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match the scrollHeight
        }
    };

    // Call the adjustTextareaHeight function whenever the message value changes
    useEffect(() => {
        adjustTextareaHeight();
    }, [message]);

    useEffect(() => {
        // Adjust the position of the popover
        if (documentSearchOpen && textareaRef.current && popoverRef.current) {
            const textareaRect = textareaRef.current.getBoundingClientRect();
            const popoverRect = popoverRef.current.getBoundingClientRect();

            setPopoverPosition({
                top: textareaRect.top - popoverRect.height, // position it above the textarea
                left: textareaRect.left, // align it with the left of the textarea
            });
            setPopoverWidth(textareaRect.width); // match the width of the textarea
        }
    }, [documentSearchOpen]);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (!documentSearchOpen) {
                return;
            }

            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target as Node)
            ) {
                updateStore(closeDocumentSearch());
            }
        }

        // Add the handleClickOutside function as an event listener for click events
        document.addEventListener('click', handleClickOutside);

        // Return a cleanup function that removes the event listener
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [documentSearchOpen]);

    const handleTextOnChange = useCallback(
        (
            _event: ChangeEvent<HTMLTextAreaElement>,
            data: TextareaOnChangeData,
        ) => {
            onChange(data.value);
            setMessageValue(data.value);
            setSpeechValue('');
            toggleRecording(false);
            resetTranscript();
        },
        [onChange, resetTranscript, toggleRecording],
    );

    const handleTextOnKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
            if (event.key === 'Enter' && message.trim()) {
                if (!event.shiftKey) {
                    event.preventDefault();
                }
                onEnterKeyPressed(event.shiftKey);
            }
            if (event.key === 'Enter' && !message.trim()) {
                event.preventDefault();
            }
        },
        [message, onEnterKeyPressed],
    );

    return (
        <div>
            {loadingSelectedDocument && files && files.length < 1 && (
                <div>
                    {' '}
                    <Card
                        style={{
                            marginBottom: '5px',
                            backgroundColor:
                                'var(--colorNeutralBackground5Hover)',
                            border: '1px solid var(--colorNeutralBackground3Hover)',
                            height: '42px',
                            width: '30%',
                            justifyContent: 'center',
                        }}
                    >
                        <Skeleton
                            animation="pulse"
                            style={{
                                display: 'flex', // Make Skeleton a flex container
                                alignItems: 'center', // Center items vertically
                            }}
                        >
                            <SkeletonItem
                                style={{
                                    width: '20px',

                                    backgroundColor:
                                        'var(--colorNeutralBackground2)',
                                }}
                            />
                            <SkeletonItem
                                style={{
                                    width: '80%',
                                    marginLeft: '10px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground2)',
                                }}
                            />
                        </Skeleton>
                    </Card>
                </div>
            )}
            <div className="SelectedFiles">
                {!isFetchingThreadDetails &&
                    files &&
                    files.length > 0 &&
                    files.map((file, index) => {
                        if (isImageFile(file.name)) {
                            return (
                                <ImageThumbnail
                                    key={file.id}
                                    threadId={threadId}
                                    attachmentId={file.id}
                                    name={file.name}
                                    url={file.url}
                                    role={'User'}
                                />
                            );
                        }
                        return (
                            <FileCard
                                threadId={threadId}
                                name={file.name}
                                url={file.url}
                                id={file.id}
                                isLoading={file.isLoading}
                                index={index}
                                disableDismissButton={false}
                                role={file.role}
                            />
                        );
                    })}
            </div>
            <div style={{ position: 'relative', width: '100%' }}>
                <Textarea
                    style={{
                        width: '100%',
                        backgroundColor: 'var(--colorNeutralBackground1)',
                        boxSizing: 'border-box',
                    }}
                    placeholder={
                        isChatDisabled
                            ? ''
                            : 'Enter your message here; you can also drag or paste files and screenshots to add them to the conversation'
                    }
                    ref={textareaRef}
                    onChange={handleTextOnChange}
                    onKeyDown={handleTextOnKeyDown}
                    onPaste={canAttachFiles ? handlePaste : undefined}
                    value={isRecording ? message + props.transcript : message}
                    disabled={
                        isChatDisabled ||
                        loadingSelectedDocument ||
                        isUploadingFile
                    }
                    data-testid="message-textarea"
                ></Textarea>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '10px', // Position the card at the bottom of the container
                        left: '10px',

                        zIndex: '1',
                    }}
                ></div>
                <ProgressBar
                    /* value={isChatDisabled ? undefined : 1} */
                    thickness="large"
                    style={{
                        borderRadius: '4px',
                        marginTop: '1px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: isChatDisabled ? 'block' : 'none',
                    }}
                />
            </div>

            <Popover open={documentSearchOpen}>
                <PopoverSurface
                    ref={popoverRef} // Attach the ref to the PopoverSurface
                    style={{
                        position: 'absolute',
                        top: `${popoverPosition.top - 2}px`, // Use the calculated top position
                        left: `${popoverPosition.left}px`, // Use the calculated left position
                        width: `${popoverWidth - 35}px`, // Set the width to match the textarea
                        height: '400px',
                        maxHeight: '45vh',
                        zIndex: 1000, // Puts it above other elements
                        backgroundColor: 'var(--colorNeutralBackground5Hover)',
                        border: '1px solid var(--colorNeutralBackground3Hover)',
                    }}
                >
                    <DocumentPopover
                        threadId={threadId}
                        setLoadingSelectedDocument={setLoadingSelectedDocument}
                    />
                </PopoverSurface>
            </Popover>
        </div>
    );
};
