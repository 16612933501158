import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Card,
    CardHeader,
    Image,
    Button,
} from '@fluentui/react-components';
import { ArrowRightRegular } from '@fluentui/react-icons';
import 'Styles/ChatCards.Module.css';
import { useState } from 'react';

import { MarkdownViewer } from 'Components/MarkdownViewer';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useLogging } from 'Hooks/useLogging';
import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { EventTypes } from 'Models/EventTypes';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

const useStyles = makeStyles({
    cardLinks: {
        marginRight: '20px',
        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '100%',

        backgroundColor: tokens.colorNeutralBackgroundStatic,

        ':hover': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
            cursor: 'pointer',
        },
        marginBottom: '7px',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    card: {
        // paddingBottom: '10px',
        marginTop: '10px',
        marginRight: '20px',

        '&:last-child': {
            marginRight: '0',
        },
        minWidth: '200px',
        backgroundColor: '#202F2F',
        marginBottom: '20px',
    },
    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center',
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },
});

type Props = {
    chatResponse: ErmChatResponseApiResource;
    userMessage: PostErmMessageRequest;
    logAndTrackErmEventSequence: (eventType: EventTypes) => void;
    postMetricsFunction: (
        url: string,
        clickEvent: EventTypes,
        messageId: number,
    ) => void;
};
export const ErmLinksRecipe: React.FC<Props> = (props: Props) => {
    const styles = useStyles();
    const { logEvent } = useLogging();
    const [itemsToShow, setItemsToShow] = useState(5);
    const postMetricsFunction = props.postMetricsFunction;
    const ermLanguage = useAppSelector(
        (store) => store.userInterface.ermLanguage,
    );

    const response = props.chatResponse;
    if (response.response === null) {
        return null;
    }
    const ermLinks = response.references;

    // Function to handle the load more button click
    const handleLoadMore = () => {
        logEvent(EventTypes.ERM_LOAD_MORE_CLICKED);
        setItemsToShow((prev) => prev + 5);
    };

    // Remove duplicate items from the list
    const uniqueErmLinks = ermLinks?.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t.url === item.url),
    );

    return (
        <div style={{ width: '85%', marginBottom: '30px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Image
                    alt="Aurora Logo"
                    src="/logo192.png"
                    shape="circular"
                    height={70}
                    width={70}
                    style={{ marginRight: '10px' }}
                />
                <Card
                    className={styles.card}
                    style={{
                        borderRadius: 5,
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        fontSize: '18px',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                    }}
                >
                    <MarkdownViewer
                        content={
                            ermLanguage === 'Spanish'
                                ? 'Los enlaces a continuación pueden contener la información que necesita.'
                                : response.response
                        }
                    />
                </Card>
            </div>
            <div>
                <p style={{ marginLeft: '80px' }}></p>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '5px 20px', // Adjust the gap between cards as needed
                        paddingLeft: '75px',
                    }}
                >
                    {uniqueErmLinks &&
                        uniqueErmLinks
                            .slice(0, itemsToShow)
                            .map((ermLinkItem, index) => (
                                <Card
                                    className={styles.cardLinks}
                                    style={{
                                        borderRadius: '40px',
                                        borderColor:
                                            tokens.colorNeutralBackground4,
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (ermLinkItem.url) {
                                            logEvent(
                                                EventTypes.ERM_LINK_CLICKED,
                                            ),
                                                postMetricsFunction(
                                                    ermLinkItem.url,
                                                    EventTypes.ERM_LINK_CLICKED,
                                                    props.chatResponse.id,
                                                );
                                            window.open(
                                                ermLinkItem.url,
                                                '_self',
                                            );
                                        }
                                    }}
                                    key={index}
                                    data-testid={`erm-recipe-link-${index}`}
                                >
                                    <CardHeader
                                        header={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between', // Pushes the icon to the far right
                                                    width: '100%',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={'/PublixLogo.png'}
                                                        alt="File icon"
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            marginRight: '10px',
                                                            marginLeft: '15px',
                                                        }}
                                                    />
                                                    <Text
                                                        style={{
                                                            marginTop: '5px',
                                                            marginLeft: '10px',
                                                            fontSize: '16.5px',
                                                        }}
                                                    >
                                                        <b>
                                                            {ermLinkItem.title}
                                                        </b>
                                                    </Text>
                                                </div>
                                                <ArrowRightRegular
                                                    style={{
                                                        marginRight: '20px',
                                                        height: '15px',
                                                        width: '15px',
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                </Card>
                            ))}
                </div>
                {uniqueErmLinks && itemsToShow < uniqueErmLinks.length && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <Button
                            style={{
                                padding: '10px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                borderRadius: '10px',
                                fontSize: '20px',
                                backgroundColor:
                                    tokens.colorNeutralBackgroundStatic,
                            }}
                            onClick={handleLoadMore}
                            data-testid="erm-load-more-recipes"
                        >
                            {ermLanguage === 'Spanish'
                                ? 'Cargar Más'
                                : 'Load More'}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
