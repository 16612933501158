import {
    Slider,
    makeStyles,
    Divider,
    Dropdown,
    Option,
    OptionGroup,
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Switch,
    SwitchOnChangeData,
    OptionOnSelectData,
    SelectionEvents,
    SliderOnChangeData,
    tokens,
} from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SkillCard } from 'Components/SkillCard';
import { Feature } from 'Config/features';
import { useFeature } from 'Hooks/useFeature';
import { Skill } from 'Models/Skill';
import { User } from 'Models/User';
import { updateSettingsDialogOpen } from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import { findChangedSkills } from 'Utils';

interface Props {
    userPreferences: User;
    setUserPreferences: React.Dispatch<React.SetStateAction<User | undefined>>;
    setIsClearThreadsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    skills: Skill[];
    initialSkills: Skill[];
    setSkillsToSend: React.Dispatch<React.SetStateAction<Skill[]>>;
    setThreadSkills: React.Dispatch<React.SetStateAction<Skill[]>>;
    setAreThereUnsavedSkillsChanges: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const useStyles = makeStyles({
    dropdown: {
        maxWidth: '100%',
        minWidth: '100%',
    },
    saveButton: {
        maxWidth: '80px',
        marginLeft: 'auto',
        marginRight: '15px',
    },
    deleteChatsButton: {
        backgroundColor: tokens.colorPaletteRedBackground3,
        '&:hover': {
            backgroundColor: tokens.colorPaletteRedForeground1,
            color: 'white',
        },
    },
});

export const SettingsMenuDefaults: React.FC<Props> = ({
    userPreferences,
    setUserPreferences,
    setIsClearThreadsDialogOpen,
    skills,
    initialSkills,
    setSkillsToSend,
    setThreadSkills,
    setAreThereUnsavedSkillsChanges,
}) => {
    const { enabled: canAccessBetaFlag } = useFeature(Feature.BetaFlag);
    const [cardHeight, setCardHeight] = useState<string | null>(null);
    const skillCardsFlexRef = useRef<HTMLDivElement>(null);
    // useEffect(() => {
    //     const selectedSkillsCount = skills.filter(
    //         (skill) => skill.isSelected,
    //     ).length;
    //     const height =
    //         selectedSkillsCount > 1
    //             ? `${skillCardsFlexRef.current?.clientHeight}px`
    //             : '';
    //     setCardHeight(height);
    // }, [skills]);

    //State
    const [updatedChatHistoryLimit, setUpdatedChatHistoryLimit] =
        useState<number>(userPreferences.chatHistoryLimit);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    //Handlers
    const handleSkillSelection = useCallback(
        (id: number) => {
            const chosenSkill = skills.find((skill) => skill.id === id);
            const disableAll =
                chosenSkill?.isSpecial === true &&
                chosenSkill.isSelected === false
                    ? true
                    : false;
            const disableSpecial =
                chosenSkill?.isSelected === false ? true : false;
            const updatedSkills = skills.map((skill) => {
                if (skill.id === id) {
                    //If skill has child skills, toggle skill & all child skills
                    if (skill.childSkills.length > 0) {
                        return {
                            ...skill,
                            isSelected: !skill.isSelected,
                            childSkills: skill.childSkills.map(
                                (childSkill) => ({
                                    ...childSkill,
                                    isSelected: !skill.isSelected,
                                }),
                            ),
                        };
                    }
                    //If skill has no child skills, toggle skill
                    return {
                        ...skill,
                        isSelected: !skill.isSelected,
                    };
                    //If disableAll tag is true, disable all skills
                } else if (disableAll === true) {
                    return {
                        ...skill,
                        isSelected: false,
                        childSkills: skill.childSkills.map((childSkill) => ({
                            ...childSkill,
                            isSelected: false,
                        })),
                    };
                } else if (
                    disableSpecial === true &&
                    skill.isSpecial === true
                ) {
                    return {
                        ...skill,
                        isSelected: false,
                        childSkills: skill.childSkills.map((childSkill) => ({
                            ...childSkill,
                            isSelected: false,
                        })),
                    };
                }
                return skill;
            });
            setThreadSkills(updatedSkills);
            //If updatedSkills are different than initialSkills, enable save button
            if (
                JSON.stringify(updatedSkills) !== JSON.stringify(initialSkills)
            ) {
                setSkillsToSend(
                    findChangedSkills(initialSkills, updatedSkills),
                );
                setAreThereUnsavedSkillsChanges(true);
            } else {
                setAreThereUnsavedSkillsChanges(false);
            }
        },
        [
            initialSkills,
            setAreThereUnsavedSkillsChanges,
            setThreadSkills,
            setSkillsToSend,
            skills,
        ],
    );

    const handleChildSkillSelection = useCallback(
        (id: number) => {
            const parentSkill = skills.find((skill) => {
                return skill.childSkills.some((childSkill) => {
                    return childSkill.id === id;
                });
            });

            const updatedSkills = skills.map((skill) => {
                if (parentSkill?.id === skill.id) {
                    const updatedChildSkills = skill.childSkills.map(
                        (childSkill) => {
                            if (childSkill.id === id) {
                                return {
                                    ...childSkill,
                                    isSelected: !childSkill.isSelected,
                                };
                            }
                            return childSkill;
                        },
                    );
                    //If there are no child skills enabled, disable the parent skill
                    const disableParent = updatedChildSkills.every(
                        (childSkill) => !childSkill.isSelected,
                    );
                    if (disableParent) {
                        return {
                            ...skill,
                            isSelected: false,
                            childSkills: updatedChildSkills,
                        };
                    }
                    return {
                        ...skill,
                        childSkills: updatedChildSkills,
                    };
                }
                return skill;
            });
            setThreadSkills(updatedSkills);
            //If updatedSkills are different than initialSkills, enable save button
            if (
                JSON.stringify(updatedSkills) !== JSON.stringify(initialSkills)
            ) {
                setSkillsToSend(
                    findChangedSkills(initialSkills, updatedSkills),
                );
                setAreThereUnsavedSkillsChanges(true);
            } else {
                setAreThereUnsavedSkillsChanges(false);
            }
        },
        [
            initialSkills,
            setAreThereUnsavedSkillsChanges,
            setThreadSkills,
            setSkillsToSend,
            skills,
        ],
    );

    const styles = useStyles();
    const handleBetaSwitchChange = useCallback(
        (
            _event: React.ChangeEvent<HTMLInputElement>,
            data: SwitchOnChangeData,
        ) => {
            setUserPreferences((prevState) => ({
                ...(prevState as User),
                beta: data.checked,
            }));
        },
        [setUserPreferences],
    );
    const handleContextInfoPopoverOpen = useCallback(
        () => setIsPopoverOpen(true),
        [],
    );
    const handleContentInfoPopoverClose = useCallback(
        () => setIsPopoverOpen(false),
        [],
    );
    const handleContextSliderTouchEnd = useCallback(() => {
        setUserPreferences((prevState) => ({
            ...(prevState as User),
            chatHistoryLimit: updatedChatHistoryLimit,
        }));
    }, [setUserPreferences, updatedChatHistoryLimit]);

    const handleContextSliderChange = useCallback(
        (
            _event: React.ChangeEvent<HTMLInputElement>,
            data: SliderOnChangeData,
        ) => setUpdatedChatHistoryLimit(data.value),
        [],
    );

    const handleDeleteThreadsClick = useCallback(() => {
        setIsClearThreadsDialogOpen(true);
        updateStore(updateSettingsDialogOpen());
    }, [setIsClearThreadsDialogOpen]);

    const handleSkillsOptionSelect = useCallback(
        (_event: SelectionEvents, data: OptionOnSelectData) => {
            handleSkillSelection(Number(data.optionValue));
        },
        [handleSkillSelection],
    );
    return (
        <>
            <div className="SettingsMenuDefaults">
                <div>
                    <label>Conversation Context</label>
                    <Popover open={isPopoverOpen}>
                        <PopoverTrigger>
                            <Button
                                appearance="transparent"
                                icon={<InfoRegular />}
                                style={{
                                    marginRight: '10px',
                                    marginBottom: '2px',
                                }}
                                onMouseEnter={handleContextInfoPopoverOpen}
                                onMouseLeave={handleContentInfoPopoverClose}
                            />
                        </PopoverTrigger>
                        <PopoverSurface
                            tabIndex={-1}
                            style={{
                                backgroundColor:
                                    'var(--colorNeutralBackground1)',
                                fontSize: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                width: '200px',
                            }}
                        >
                            {
                                'Select the number of past messages to include in each new request. This helps give the model context for new user queries. Setting this number to 10 will include 10 user messages and 10 system responses.'
                            }
                        </PopoverSurface>
                    </Popover>
                </div>

                <div className="SettingsMenuChatHistoryFlex">
                    <div
                        onMouseUp={handleContextSliderTouchEnd}
                        onTouchEnd={handleContextSliderTouchEnd}
                    >
                        <Slider
                            value={updatedChatHistoryLimit}
                            min={0}
                            max={15}
                            onChange={handleContextSliderChange}
                            data-testid="settings-conversation-context-slider"
                        ></Slider>
                    </div>
                    <div
                        style={{ minWidth: '18px', textAlign: 'right' }}
                        data-testid="settings-conversation-context-value"
                    >
                        {updatedChatHistoryLimit}
                    </div>
                </div>
                {canAccessBetaFlag && (
                    <>
                        <Divider />

                        <label>Enable Beta features</label>

                        <Switch
                            style={{ marginRight: '-7px' }}
                            checked={userPreferences.beta}
                            onChange={handleBetaSwitchChange}
                            data-testid="settings-beta-flag"
                        />
                    </>
                )}
                <Divider />

                <label>Delete All Threads</label>
                <Button
                    className={styles.deleteChatsButton}
                    onClick={handleDeleteThreadsClick}
                >
                    Delete
                </Button>

                <Divider />
                <label>Default Skills</label>
                <Dropdown
                    className={styles.dropdown}
                    placeholder={
                        skills &&
                        skills.length > 0 &&
                        skills.filter((s) => s.isSelected === true).length > 0
                            ? skills.filter((s) => s.isSelected === true)[0]
                                  .name + '...'
                            : 'Select a skill'
                    }
                    multiselect={true}
                    onOptionSelect={handleSkillsOptionSelect}
                    selectedOptions={skills
                        .filter((s) => s.isSelected === true)
                        .map((s) => String(s.id))}
                    data-testid="select-default-skill-dropdown"
                >
                    <OptionGroup label="Basic">
                        {skills
                            .filter((s) => s.isSpecial === false)
                            .map((skill) => (
                                <Option
                                    key={skill.id}
                                    value={String(skill.id)}
                                    data-testid={`default-skill-checkbox-${skill.id}`}
                                >
                                    {skill.name}
                                </Option>
                            ))}
                    </OptionGroup>
                    <OptionGroup label="Special">
                        {skills
                            .filter((s) => s.isSpecial === true)
                            .map((skill) => (
                                <Option
                                    key={skill.id}
                                    value={String(skill.id)}
                                    data-testid={`default-skill-checkbox-${skill.id}`}
                                >
                                    {skill.name}
                                </Option>
                            ))}
                    </OptionGroup>
                </Dropdown>
                <Divider />
                <div
                    className="SettingsMenuSkillCardsFlex"
                    ref={skillCardsFlexRef}
                >
                    {skills
                        .filter((s) => s.isSelected === true)
                        .map((skill) => (
                            <SkillCard
                                key={skill.id}
                                skillData={skill}
                                showSwitch={false}
                                handleSkillSelection={handleChildSkillSelection}
                                cardWidth="200px"
                                cardHeight={cardHeight ? cardHeight : ''}
                                stableId={`default-skill-${skill.name}`}
                            />
                        ))}
                </div>
            </div>
        </>
    );
};
