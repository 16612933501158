import { Button } from '@fluentui/react-components';
import {
    DocumentCopyRegular,
    CheckboxCheckedRegular,
} from '@fluentui/react-icons';
import { useCallback, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
    tomorrowNightBright,
    tomorrow,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';

import { TimeSavedSurvey } from 'Components/TimeSavedSurvey';
import { useAppSelector } from 'Hooks/useAppSelector';

type MarkdownViewerProps = {
    content: string;
    windowWidth?: number;
    inAuroraMessage?: boolean;
    messageId?: number;
    shouldShowTimeSavedSurvey?: boolean;
};

export const MarkdownViewer = (props: MarkdownViewerProps) => {
    const {
        content,
        windowWidth,
        inAuroraMessage,
        shouldShowTimeSavedSurvey,
        messageId,
    } = props;
    const [copySuccess, setCopySuccess] = useState(false);
    const formattedContentRef = useRef<HTMLDivElement>(null);
    const theme = useAppSelector((store) => store.user).theme;

    const copyFormattedMarkdown = useCallback(() => {
        const formattedContentEl = formattedContentRef.current;
        if (formattedContentEl) {
            try {
                // Temporarily make the div visible and contenteditable
                formattedContentEl.style.display = 'block';
                formattedContentEl.contentEditable = 'true';

                // Select the content
                const range = document.createRange();
                range.selectNodeContents(formattedContentEl);
                const sel = window.getSelection();
                if (sel !== null) {
                    sel.removeAllRanges();
                    sel.addRange(range);

                    // Copy the selection
                    document.execCommand('copy');

                    // Clean up by removing the selection and hiding the div again

                    sel.removeAllRanges();
                }
                formattedContentEl.contentEditable = 'false';
                setCopySuccess(true);
                setTimeout(() => {
                    setCopySuccess(false); // Revert back to the original icon after 3 seconds
                }, 3000);

                // Perform post-copy actions here (like showing a success message)
            } catch (error) {
                console.error('Failed to copy formatted markdown: ', error);
            }
        }
    }, []);
    if (!content || content === '') return null;
    return (
        <>
            <div ref={formattedContentRef} data-testid="markdown-viewer">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        code(props) {
                            const {
                                children,
                                className,
                                node,
                                ref,
                                style,
                                ...rest
                            } = props;
                            const match = /language-(\w+)/.exec(
                                className || '',
                            );
                            const startLine = node?.position?.start.line;
                            const endLine = node?.position?.end.line;
                            const isInline = startLine === endLine;
                            return isInline ? (
                                <code
                                    style={{
                                        backgroundColor:
                                            'var(--colorNeutralBackground2)',
                                        padding: '2px 5px',
                                    }}
                                >
                                    {children}
                                </code>
                            ) : (
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <>
                                        {match ? (
                                            <SyntaxHighlighter
                                                {...rest}
                                                PreTag="div"
                                                children={String(
                                                    children,
                                                ).replace(/\n$/, '')}
                                                language={match[1]}
                                                style={
                                                    theme === 'Light'
                                                        ? tomorrow
                                                        : tomorrowNightBright
                                                }
                                                customStyle={{
                                                    backgroundColor:
                                                        'var(--colorNeutralBackground2)',
                                                    minHeight: '40px',
                                                }}
                                            />
                                        ) : (
                                            <code
                                                {...rest}
                                                className={className}
                                            >
                                                {children}
                                            </code>
                                        )}

                                        <MarkdownViewerCodeCopyButton
                                            children={String(children)}
                                        />
                                    </>
                                </div>
                            );
                        },
                        ul: (props) => (
                            <ul
                                {...props}
                                style={{
                                    marginBlock: 0,
                                    paddingInlineStart: 20,
                                }}
                            />
                        ),
                        ol: (props) => (
                            <ol
                                {...props}
                                style={{
                                    marginBlock: 0,
                                    paddingInlineStart: 20,
                                }}
                            />
                        ),
                        li: (props) => (
                            <li
                                {...props}
                                style={{
                                    marginBlock: 0,
                                }}
                            />
                        ),
                        a: (props) => (
                            <a
                                {...props}
                                target="_blank"
                                style={{
                                    color: 'var(--colorBrandForegroundLink)',
                                }} // Specify the color here
                            />
                        ),
                        table: (props) => (
                            <div style={{ overflowX: 'auto' }}>
                                <table
                                    {...props}
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                        border: '1px solid none',
                                        backgroundColor:
                                            'var(--colorNeutralBackground2)',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        minWidth: '100%',
                                    }}
                                />
                            </div>
                        ),
                        th: (props) => (
                            <th
                                {...props}
                                style={{
                                    borderBottom:
                                        '1px solid var(--colorNeutralBackground3Hover)',
                                    padding: '2px',
                                }}
                            />
                        ),
                        td: (props) => (
                            <td
                                {...props}
                                style={{
                                    borderBottom:
                                        '1px solid var(--colorNeutralBackground3Hover)',
                                    padding: '2px',
                                }}
                            />
                        ),
                    }}
                >
                    {content}
                </ReactMarkdown>
            </div>
            {inAuroraMessage && (
                <div
                    style={{
                        marginLeft:
                            windowWidth && windowWidth < 900 ? '5px' : '10px',
                        marginTop: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minWidth: '100%',
                    }}
                >
                    <Button
                        icon={
                            copySuccess ? (
                                <CheckboxCheckedRegular />
                            ) : (
                                <DocumentCopyRegular />
                            )
                        }
                        style={{
                            fontSize: '13px',
                            marginRight: '8px',
                        }}
                        onClick={copyFormattedMarkdown}
                        data-testid="message-copy-button"
                    >
                        {windowWidth && windowWidth < 600
                            ? ''
                            : copySuccess
                              ? 'Copied'
                              : 'Copy'}
                    </Button>

                    <TimeSavedSurvey
                        messageId={messageId}
                        disabled={!shouldShowTimeSavedSurvey}
                    />
                </div>
            )}
        </>
    );
};

function MarkdownViewerCodeCopyButton({ children }: { children: string }) {
    const [isCopyButtonClicked, setIsCopyButtonClicked] =
        useState<boolean>(false);
    return (
        <Button
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1,
            }}
            icon={
                isCopyButtonClicked ? (
                    <CheckboxCheckedRegular />
                ) : (
                    <DocumentCopyRegular />
                )
            }
            onClick={async () => {
                try {
                    if (isCopyButtonClicked) return;
                    await navigator.clipboard.writeText(children);
                    setIsCopyButtonClicked(true);
                    setTimeout(() => {
                        setIsCopyButtonClicked(false);
                    }, 3000);
                } catch (error) {
                    console.error('Failed to copy text: ', error);
                }
            }}
            data-testid="code-copy-button"
        ></Button>
    );
}
