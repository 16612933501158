import {
    makeStyles,
    shorthands,
    Dropdown,
    Option,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate

import { EnableMicrophoneInstructions } from 'Components/EnableMicrophoneInstructions';
import { UploadFilesPopover } from 'Components/ScreenPopover';
import { ErmLinksRecipe } from 'Containers/ErmLinksRecipe';
import { ErmLinksRegular } from 'Containers/ErmLinksRegular';
import { ErmMessageCard } from 'Containers/ErmMessageCard';
import { ErmTutorial } from 'Containers/ErmTutorial';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useErmSpeechRecognitionHook } from 'Hooks/useErmSpeechRecognitionHook';
import { useLogging } from 'Hooks/useLogging';
import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { EventTypes } from 'Models/EventTypes';
import { ErmMetricsEvent } from 'Models/Metrics';
import {
    useGetErmThreadDetailsQuery,
    usePostErmMessageMutation,
    usePostErmThreadMutation,
    useGetErmThreadMessagesQuery,
    useSaveErmMetricMutation,
} from 'Services/API/Aurora';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';
import {
    toggleErmLoading,
    setSelectedThreadId,
    setErmLanguage,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

const chatContainerStyles = makeStyles({
    chatContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    header: {
        ...shorthands.gridArea('header'),
        alignItems: 'center',
    },
    chatArea: {
        ...shorthands.gridArea('chatArea'),
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifySelf: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        minWidth: '80%',
    },
    floatingButton: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        maxWidth: '100px',
        display: 'flex',
    },
    LanguageButton: {
        borderRadius: '20px',
        backgroundColor: 'transparent',
        border: 'transparent',
        width: '100px',
        fontSize: '12px',
    },
});

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

const useErmThread = (UrlThreadIdInt: number) => {
    const navigate = useNavigate();
    const [postErmThread] = usePostErmThreadMutation();
    const [isNewMessage, setIsNewMessage] = useState<boolean>(true);

    useEffect(() => {
        if (!UrlThreadIdInt) {
            postErmThread()
                .unwrap()
                .then((createThread) => {
                    navigate(`/ERM/${createThread.id}`);
                });
        }
    }, [UrlThreadIdInt, postErmThread, navigate]);

    return { isNewMessage, setIsNewMessage };
};

const useErmMessages = (UrlThreadIdInt: number) => {
    const { data, isFetching: isFetchingErmMessages } =
        useGetErmThreadMessagesQuery(
            { threadId: UrlThreadIdInt ?? 0, pageNumber: 1 },
            { skip: !UrlThreadIdInt },
        );

    return { data, isFetchingErmMessages };
};

const useErmDetails = (threadId: number) => {
    const { data: selectedThread, isFetching: isFetchingDetails } =
        useGetErmThreadDetailsQuery(threadId ?? 0, {
            skip: !threadId,
        });

    return { selectedThread, isFetchingDetails };
};

export const ErmContainer: React.FC = () => {
    const [ermEventSequence, setErmEventSequence] = useState<ErmMetricsEvent>(
        [],
    );
    const { logEvent } = useLogging();
    const styles = chatContainerStyles();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const { UrlThreadId } = useParams<{ UrlThreadId: string }>();
    const UrlThreadIdInt = parseInt(UrlThreadId!, 10);
    const { isNewMessage, setIsNewMessage } = useErmThread(UrlThreadIdInt);
    const { data, isFetchingErmMessages } = useErmMessages(UrlThreadIdInt);
    const threadId =
        useAppSelector((store) => store.thread.selectedThreadId) ?? 0;
    const { selectedThread, isFetchingDetails } = useErmDetails(threadId);

    const [
        postMessage,
        { isLoading: isPostingMessage, status: postMessageStatus },
    ] = usePostErmMessageMutation();
    const [postMetrics] = useSaveErmMetricMutation();
    const [userMessage, setUserMessage] = useState<PostErmMessageRequest>({
        threadId: 0,
        message: '',
    });
    const [messageResponse, setMessageResponse] =
        useState<ErmChatResponseApiResource>();
    const [tutorialMessage, setTutorialMessage] = useState('');
    const [isChatInProgress, setIsChatInProgress] = useState<boolean>(false);
    const [chatMessage, setChatMessage] = useState<string>('');
    const [isDragPopoverOpen, setIsDragPopoverOpen] = useState(false);
    const [isTutorialOpen, setIsTutorialOpen] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const options = ['English', 'Spanish'];
    const ermLanguage = useAppSelector(
        (store) => store.userInterface.ermLanguage,
    );

    const logAndTrackErmEventSequence = (eventType: EventTypes) => {
        logEvent(eventType);
        const currentTime = new Date().toISOString();

        setErmEventSequence((prevMetrics: ErmMetricsEvent) => [
            ...prevMetrics,
            {
                event: eventType,
                requestTime: currentTime,
            },
        ]);
    };
    const postMetricsFunction = (
        url: string,
        clickEvent: EventTypes,
        messageId: number,
    ) => {
        const ermMetrics = [
            {
                event: clickEvent,
                threadId: threadId,
                messageId: messageId,
                requestTime: new Date().toISOString(),
                url: url,
            },
        ];
        postMetrics(ermMetrics);
    };

    const {
        record,
        transcript,
        resetTranscript,
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
        toggleRecording,
    } = useErmSpeechRecognitionHook();

    // Handle thread selection
    const handleSelectThread = useCallback((id: number) => {
        updateStore(setSelectedThreadId(id));
    }, []);

    useEffect(() => {
        if (UrlThreadIdInt) {
            handleSelectThread(UrlThreadIdInt);
        }
    }, [UrlThreadIdInt, handleSelectThread]);

    // Handle fetching messages
    useEffect(() => {
        if (data && data.items?.length > 0) {
            setIsNewMessage(false);
            setUserMessage({
                message: data.items[0]?.response ?? '',
                threadId: UrlThreadIdInt ?? 0,
            });
            setMessageResponse(data.items[1]);
        }
    }, [data]);

    // Handle scrolling to new message
    const handleScrollNewMessage = useCallback(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.clientHeight -
                scrollContainerRef.current.scrollHeight;
        }
    }, [scrollContainerRef]);

    useEffect(() => {
        handleScrollNewMessage();
    }, [postMessageStatus, handleScrollNewMessage, isNewMessage]);

    // Handle resetting transcript
    const toggleResetTranscript = () => {
        resetTranscript();
    };

    // Handle loading state
    const setErmLoading = (isLoading: boolean) => {
        updateStore(toggleErmLoading(isLoading));
    };

    useEffect(() => {
        setErmLoading(isPostingMessage);
    }, [isPostingMessage]);

    // Show Erm links
    const showErmLinks = () => {
        if (!messageResponse || isNewMessage) {
            return <></>;
        }
        return messageResponse.isRecipe ? (
            <ErmLinksRecipe
                chatResponse={messageResponse}
                userMessage={userMessage}
                logAndTrackErmEventSequence={logAndTrackErmEventSequence}
                postMetricsFunction={postMetricsFunction}
            />
        ) : (
            <ErmLinksRegular
                chatResponse={messageResponse}
                userMessage={userMessage}
                logAndTrackErmEventSequence={logAndTrackErmEventSequence}
                postMetricsFunction={postMetricsFunction}
            />
        );
    };

    // Send chat message
    const sendChatMessage = async (
        message?: string,
        ermMetrics: ErmMetricsEvent = ermEventSequence,
        ermLanguageSetting: string = ermLanguage,
    ) => {
        setIsChatInProgress(true);
        setChatMessage('');
        setUserMessage({
            message: message ?? chatMessage.trim(),
            threadId: selectedThread?.id ?? 0,
        });
        logEvent(EventTypes.ERM_SEND_BUTTON_CLICKED);

        const updatedErmEventSequence: ErmMetricsEvent = [
            ...ermMetrics,
            {
                event: EventTypes.ERM_SEND_BUTTON_CLICKED,
                requestTime: new Date().toISOString(),
            },
        ];

        try {
            const result = await postMessage({
                threadId: threadId ?? 0,
                message: message ?? chatMessage.trim(),
                ermMetrics: updatedErmEventSequence ?? [],
                ermLanguageSetting: ermLanguageSetting ?? '',
            });

            if ('data' in result && result.data.data) {
                setMessageResponse(result.data.data);
            } else {
                console.error(
                    'Unexpected result from awaited postMessage',
                    result,
                );
            }
        } finally {
            setIsChatInProgress(false);
            setIsNewMessage(false);
            setErmEventSequence([]);
        }
    };

    if (isFetchingErmMessages || !UrlThreadIdInt) {
        return (
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                Loading...
            </div>
        );
    }

    return (
        <div
            className={styles.chatContainer}
            style={{
                height: windowHeight,
                background: 'var(--colorNeutralBackground5)',
            }}
        >
            <UploadFilesPopover content={<></>} isOpen={isDragPopoverOpen} />
            <div className={styles.header}></div>
            <div
                ref={scrollContainerRef}
                className={styles.chatArea + ' Erm-custom-scrollbar'}
                style={{ alignItems: 'center', width: windowWidth }}
            >
                <>
                    <ErmTutorial
                        isNewMessage={isNewMessage}
                        setTutorialMessage={setTutorialMessage}
                        isTutorialOpen={isTutorialOpen}
                        setIsTutorialOpen={setIsTutorialOpen}
                        windowWidth={windowWidth}
                    />
                    {showErmLinks()}
                    <ErmMessageCard
                        userMessage={userMessage}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        resetTranscript={toggleResetTranscript}
                        transcript={transcript}
                        isSendMessageButtonDisabled={
                            isChatInProgress || chatMessage.trim().length === 0
                        }
                        onSendMessageButtonClick={async (
                            message,
                            ermMetrics,
                            ermLanguageSetting,
                        ) => {
                            await sendChatMessage(
                                message,
                                ermMetrics,
                                ermLanguageSetting,
                            );
                        }}
                        onChange={(value) => setChatMessage(value)}
                        isNewMessage={isNewMessage}
                        setIsNewMessage={setIsNewMessage}
                        tutorialMessage={tutorialMessage}
                        logAndTrackErmEventSequence={
                            logAndTrackErmEventSequence
                        }
                    />
                </>
            </div>
            {isMicrophonePermissionPopupVisible && (
                <EnableMicrophoneInstructions
                    onPopupClosed={() =>
                        setIsMicrophonePermissionPopupVisible(false)
                    }
                />
            )}

            <div className={styles.floatingButton}>
                <Dropdown
                    id="LanguageDropdown"
                    placeholder={ermLanguage}
                    defaultSelectedOptions={['English']}
                    className={styles.LanguageButton}
                    onOptionSelect={(event, data) => {
                        updateStore(setErmLanguage(data.optionValue as string));
                        data.optionValue === 'English'
                            ? logAndTrackErmEventSequence(
                                  EventTypes.ERM_ENGLISH_BUTTON_CLICKED,
                              )
                            : logAndTrackErmEventSequence(
                                  EventTypes.ERM_SPANISH_BUTTON_CLICKED,
                              );
                    }}
                    style={{
                        minWidth: '120px',
                    }}
                >
                    {options.map((option) => (
                        <Option key={option}>{option}</Option>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
};
