import {
    makeStyles,
    shorthands,
    Button,
    Card,
} from '@fluentui/react-components';
import 'Styles/ChatCards.Module.css';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from 'Hooks/useAppSelector';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';

import 'Styles/ErmMicrophone.Module.css';

type Props = {
    isNewMessage: boolean;
    setTutorialMessage: (value: string) => void;
    isTutorialOpen: boolean;
    setIsTutorialOpen: (value: boolean) => void;
    windowWidth: number;
};

const useStyles = makeStyles({
    floatingButton: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        ...shorthands.padding('10px'),
        paddingRight: '20px',
        paddingLeft: '20px',
        fontSize: '18px',
    },
});

export const ErmTutorial: React.FC<Props> = (props: Props) => {
    const ErmLoading = useAppSelector(
        (store) => store.userInterface.ErmLoading,
    );
    const styles = useStyles();
    const {
        isNewMessage,
        setTutorialMessage,
        isTutorialOpen,
        setIsTutorialOpen,
        windowWidth,
    } = props;
    const [currentStep, setCurrentStep] = useState(0);

    const stepsQuestionPage = [
        {
            content: 'This is the question you asked.',
            style:
                windowWidth > 1201
                    ? { top: '45%', left: '65%' }
                    : { top: '33%', left: '50%' },
            action: '',
        },
        {
            content: "This is Aurora's answer to your question.",
            style:
                windowWidth > 1201
                    ? { top: '45%', left: '20%' }
                    : { top: '48%', left: '80%', width: '30%' },
            action: '',
        },
        {
            content:
                'These are links to the relevant ERM Sharepoint pages, you can click them to navigate to the page.',
            style:
                windowWidth > 1201
                    ? { bottom: '0', left: '75%', width: '40%' }
                    : { bottom: '6%', left: '50%', width: '80%' },
            action: '',
        },
        {
            content: 'Click the Aurora Button to ask another question.',
            style:
                windowWidth > 1201
                    ? { top: '22%', left: '20%' }
                    : { top: '15%', left: '50%', width: '80%' },
            action: '',
        },
    ];
    const stepsNewMessagePage = [
        {
            content:
                'Welcome to Aurora, this is a tutorial to help you get started with this app.',
            style:
                windowWidth > 1201
                    ? { top: '20%', left: '50%' }
                    : { top: '30%', left: '50%' },
            action: '',
        },
        {
            content:
                'Click the Aurora Button below to ask a question using voice, you can tap the button again to stop recording.',
            style:
                windowWidth > 1201
                    ? { top: '20%', left: '50%' }
                    : { top: '30%', left: '50%', width: '60%' },

            action: '',
        },
        {
            content: 'To type a question you can click the Textarea above.',
            style:
                windowWidth > 1201
                    ? { bottom: '0%', left: '50%' }
                    : { top: '70%', left: '50%' },
            action: '',
        },
        {
            content: "Let's try an example question:",
            style:
                windowWidth > 1201
                    ? { bottom: '15%', left: '22%' }
                    : { top: '70%', left: '50%' },
            action: 'What is the PLU of the Miami Dolphin sub?',
        },
        {
            content: '',
            style:
                windowWidth > 1201
                    ? { bottom: '25%', left: '22%' }
                    : { top: '72%', left: '50%' },
            action: 'What is the PLU of the Miami Dolphin sub?',
        },
        {
            content: 'To clear the text you can click the "Clear" Button.',
            style:
                windowWidth > 1201
                    ? { top: '90%', left: '35%' }
                    : { top: '72%', left: '50%', width: '70%' },
            action: '',
        },
        {
            content: 'To send the question you can click the "Send" Button.',
            style:
                windowWidth > 1201
                    ? { top: '90%', left: '65%' }
                    : { top: '72%', left: '50%', width: '70%' },
            action: '',
        },
    ];
    const steps = isNewMessage ? stepsNewMessagePage : stepsQuestionPage;

    const handleNext = (value: string) => {
        if (value) {
            setIsTutorialOpen(false);
            setTutorialMessage(value);
            setIsTutorialOpen(true);
        }
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            setIsTutorialOpen(false);
            setCurrentStep(0);
            setTutorialMessage('');
        }
    };
    return isTutorialOpen ? (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 9999, // Ensure it's on top of other elements
            }}
        >
            <Button
                className={styles.floatingButton}
                onClick={() => {
                    setIsTutorialOpen(false);
                    setTutorialMessage('');
                    setCurrentStep(0);
                }}
                // onMouseDown={handleMouseDown}
                // onMouseUp={handleMouseUp}
                style={{
                    borderRadius: '20px',
                    userSelect: 'none',
                    WebkitTapHighlightColor: 'transparent',
                }}
            >
                Skip Tutorial
            </Button>
            {steps.map((step, index) => (
                <Card
                    key={index}
                    style={{
                        ...step.style,
                        position: 'absolute',
                        textAlign: 'center', // Center text horizontally
                        display: 'flex', // Center content
                        justifyContent: 'center', // Center content horizontally
                        alignItems: 'center',
                        transform: 'translate(-50%, -50%)',
                        opacity: currentStep === index ? 1 : 0,
                        transition: 'opacity 0.5s',
                        pointerEvents: currentStep === index ? 'auto' : 'none',
                        fontSize: '18px',
                    }}
                >
                    {step.content}
                    <Button
                        style={{
                            display: 'block',
                            marginTop: '10px',
                            fontSize: '16px',
                            padding: '10px',
                        }}
                        onClick={() => handleNext(step.action)}
                    >
                        {currentStep === steps.length - 1
                            ? 'Try it out!'
                            : 'Next'}
                    </Button>
                </Card>
            ))}
        </div>
    ) : null;
};
