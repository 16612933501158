import { ErmChatResponseApiResource } from 'Models/ChatThread';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import { SaveErmMetricRequest } from 'Services/API/Aurora/dto';

export const saveErmMetric = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, SaveErmMetricRequest>({
        query: (body) => ({
            url: '/v1/Erm/SaveErmMetric',
            method: 'POST',
            body,
        }),
    });
