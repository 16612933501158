import { intersection } from 'lodash';

import { Feature, FEATURE_CONFIG } from 'Config/features';
import { useRoles } from 'Hooks/useRoles';

export const useFeature = (
    feature: Feature,
): { enabled: boolean; isLoading: boolean } => {
    const userRoles = useRoles();
    if (userRoles.length === 0) {
        return { enabled: false, isLoading: true };
    }
    const featureRoles = FEATURE_CONFIG[feature];
    if (intersection(featureRoles, userRoles).length > 0) {
        return { enabled: true, isLoading: false };
    }
    return { enabled: false, isLoading: false };
};
