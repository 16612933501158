import { unionBy } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { AuroraEndpointBuilder } from 'Services/API/Aurora';
import {
    GetErmThreadMessagesResponse,
    GetErmThreadMessagesRequest,
} from 'Services/API/Aurora/dto';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const getErmThreadMessages = (builder: AuroraEndpointBuilder) =>
    builder.query<GetErmThreadMessagesResponse, GetErmThreadMessagesRequest>({
        query: ({ threadId, pageNumber }) =>
            `/v1/Erm/GetErmMessages?threadId=${threadId}&PageNumber=${pageNumber}`,
        transformResponse: (response: {
            data: GetErmThreadMessagesResponse;
        }) => {
            return response.data;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
            `${endpointName}?threadId=${queryArgs.threadId}`,
        merge: (currentCache, newItems) => {
            return {
                ...newItems,
                items: unionBy(newItems.items, currentCache.items, 'id').sort(
                    (a, b) =>
                        new Date(a.createdAt).valueOf() -
                        new Date(b.createdAt).valueOf(),
                ),
            };
        },
        transformErrorResponse: (response: { status: string | number }) => {
            return response.status;
        },
        forceRefetch({ currentArg, previousArg }) {
            return currentArg?.pageNumber !== previousArg?.pageNumber;
        },
        providesTags: (_result, _error, { threadId }) => [
            {
                type: 'Messages' as const,
                id: threadId,
            },
        ],

        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch (e) {
                console.error('Error on getErmThreadMessages:', e);
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ErmThreadMessagesGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
    });
